<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">NEW LOT</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form2" class="multi-col-validation">
        <v-flex xs12 md4>
          <v-text-field
            class="mx-2 mt-5"
            v-model="date_registered"
            label="Date Registered"
            dense
            outlined
            type="date"
            :rules="rules.default_max_45_character_and_no_empty_rule"
          ></v-text-field>
        </v-flex>
        <v-layout row wrap class="align-center mx-2 mt-3">
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="branch_code"
              label="Lot Code"
              dense
              outlined
              :rules="rules.default_max_255_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12>
            <v-textarea
              class="mx-2"
              v-model="address"
              dense
              label="Address"
              outlined
              :rules="rules.default_no_empty_rule"
            ></v-textarea>
          </v-flex>
          <v-flex xs12 md4>
            <v-text-field
              class="mx-2"
              v-model="room_counter"
              label="How many raw lots?"
              dense
              outlined
              type="number"
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-col cols="12" v-show="alert">
            <v-alert
              color="warning"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{alert_message}}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
          <v-col cols="12" md="12">
            <v-btn
              color="primary"
              @click="save_request"
              v-if="!saving_data"
            >
              Save Changes
            </v-btn>
            <v-progress-circular
              :size=50
              :width="5"
              color="primary"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-col>
        </v-layout>
      </v-form>
    </v-card>
    <list-of-branches :key="this.key"/>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiCheck, mdiClose
    , mdiAccountOff
    , mdiAccountCheck
    , mdiPlus
    , mdiEyeCircle
  } from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import ListOfBranches from '@/views/register_interface/branch_component/ListOfBranches'

  const initialState = () => {
    return {
      saving_data: false,
      alert: false,
      alert_message: '',

      is_edit_room: false,
      key: 0,

      date_registered: '',
      branch_code: '',
      contact_person: '',
      address: '',
      type_of_lessee: '',
      type_of_business: '',
      contact_no: '',
      room_counter: '',
    }
  }
  export default {
    components: {
      snackBarDialog,
      ListOfBranches,
    },
    setup() {
      return {
        icons: {
          mdiCheck,
          mdiClose,
          mdiAccountOff
          , mdiAccountCheck
          , mdiPlus
          , mdiEyeCircle
        },
      }
    },
    data() {
      return initialState()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('branch_information', ['create_branch']),
      save_request() {
        this.saving_data = true
        this.alert = false

        if (this.$refs.form2.validate()) {
          const data = new FormData()
          data.append('date_registered', this.date_registered);
          data.append('room_counter', this.room_counter);
          data.append('branch_code', this.branch_code.toUpperCase());
          data.append('address', this.address.toUpperCase());
          data.append('contact_no', this.contact_no);
          data.append('contact_person', this.contact_person.toUpperCase());
          this.create_branch(data)
            .then(response => {
              var color = 'error'
              if (response.status === 200) {
                color = 'success'
                Object.assign(this.$data, initialState())
                this.key++
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.saving_data = false
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
      view_rooms(item) {
        this.selected_branch = item
        const data = new FormData()
        data.append('member_id', item.id);
        this.get_list_of_business_owner(data)
          .then(response => {
            this.is_view_business_owner = true
            this.data_items2 = response.data
          })
      },
    },
  }
</script>
